<template>
    <div class="PublishArticle app-main-card">
        <a-form
            class="PublishArticle-from"
            ref="publishForm"
            id="components-form-demo-validate-other"
            :form="form"
            v-bind="formItemLayout"
            @submit="handleSubmit"
        >
            <a-form-item v-bind="formItemLayout">
                <span slot="label"> 文章标题 </span>
                <a-input
                    placeholder="请输入文章标题"
                    v-decorator="[
                        'articleTitle',
                        {
                            rules: [{required: true, message: '请输入文章标题!', whitespace: true}],
                        },
                    ]"
                />
            </a-form-item>
            <a-form-item v-bind="formItemLayout">
                <span slot="label"> 描述 </span>
                <a-input placeholder="请简要概述本篇文章" v-decorator="['articleDesc']" />
            </a-form-item>
            <a-form-item label="文章分类">
                <a-select v-decorator="['articleClassify', {initialValue: 1}]" placeholder="Please select a article classify">
                    <a-select-option :value="item.value" :key="item.value" v-for="item in ACTIVITY_CLASSIFY"> {{ item.label }}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="文章类型" has-feedback>
                <a-select v-decorator="['articleType', {initialValue: 1}]" placeholder="Please select a  article type">
                    <a-select-option :value="item.value" :key="item.value" v-for="item in ARTICLE_TYPE"> {{ item.label }}</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="文章标签">
                <a-select mode="multiple" placeholder="Please select favourite colors" v-decorator="['articleTag']">
                    <a-select-option v-for="item in ACTIVITY_TAG" :key="item.value" :value="item.value"> {{ item.label }} </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item v-if="!editStatus" label="文章封面" extra="根据文章类型上传适当的图片">
                <a-upload
                    ref="uploadImg"
                    name="files"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    action="/root/articleCoverImg"
                    :before-upload="beforeUpload"
                    @change="handleChange"
                    :remove="handlerDelete"
                    v-decorator="[
                        'article_cover',
                        {
                            valuePropName: 'fileList',
                            getValueFromEvent: normFile,
                        },
                        {rules: [{required: true, message: '请上传封面图'}]},
                    ]"
                >
                    <img class="article-cover" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                        <a-icon :type="loading ? 'loading' : 'plus'" />
                        <div class="ant-upload-text">Upload</div>
                    </div>
                </a-upload>
            </a-form-item>

            <!--  <a-form-item v-if="!editStatus" label="上传文档">
                <div class="dropbox">
                    <a-upload-dragger
                        ref="uploadFile"
                        v-decorator="[
                            'mdFile',
                            {
                                valuePropName: 'fileList',
                                getValueFromEvent: normFile,
                            },
                        ]"
                        name="files"
                        action="api/root/articleMd"
                        :remove="handlerDelete"
                    >
                        <p class="ant-upload-drag-icon">
                            <a-icon type="inbox" />
                        </p>
                        <p class="ant-upload-text">Click or drag file to this area to upload</p>
                        <p class="ant-upload-hint">Support for a single or bulk upload.</p>
                    </a-upload-dragger>
                </div>
            </a-form-item> -->
            <a-form-item v-if="!editStatus" label="编辑文档"> </a-form-item>
            <a-form-item :wrapper-col="{span: 12, offset: 4}" class="mt-10px">
                <a-button type="primary" html-type="submit"> 发布 </a-button>
            </a-form-item>
            <mavon-editor
                class="mavon-editor"
                ref="md"
                v-model="mark_content"
                :externalLink="externalLink"
                :subfield="subfield"
                :code_style="code_style"
                :ishljs="true"
                @save="saveArticle"
                @imgAdd="$imgAdd"
                @imgDel="$imgDel"
            />
        </a-form>
    </div>
</template>

<script>
    import {ARTICLE_TYPE, ACTIVITY_TAG, ACTIVITY_CLASSIFY} from "@/lib/article_const"
    import {getBase64} from '@/utils/ant'
    import {mapActions} from 'vuex';
    export default {
        data: () => ({
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 14},
            },
            ARTICLE_TYPE,
            ACTIVITY_TAG,
            ACTIVITY_CLASSIFY,
            loading: false,
            imageUrl: '',
            fileList: [],
            // editStatus: false, // false 为发布模式，true 为文章编辑模式
            mark_content: '',
            subfield: true,
            code_style: 'solarized-dark',
            externalLink: {
                markdown_css: function () {
                    // 这是你的markdown css文件路径
                    return '/markdown/github-markdown.min.css';
                },
                hljs_js: function () {
                    // 这是你的hljs文件路径
                    return '/highlightjs/highlight.min.js';
                },
                hljs_css: function (css) {
                    // 这是你的代码高亮配色文件路径
                    return '/highlightjs/styles/' + css + '.min.css';
                },
                hljs_lang: function (lang) {
                    // 这是你的代码高亮语言解析路径
                    return '/highlightjs/languages/' + lang + '.min.js';
                },
                katex_css: function () {
                    // 这是你的katex配色方案路径路径
                    return '/katex/katex.min.css';
                },
                katex_js: function () {
                    // 这是你的katex.js路径
                    return '/katex/katex.min.js';
                },
            }
        }),
        computed: {
            editStatus() {
                return this.$route.query.articleId ? true : false
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'validate_other'});
        },
        methods: {
            ...mapActions({
                vxHandlerDelete: 'root/handlerDelete', vxHandlerSaveArticle: 'root/handlerSaveArticle',
                vxGetOneArticle: 'root/getOneArticle',
                vxChangeArticleInfo: 'root/changeArticleInfo',
                vxDeleteArticleImg: 'root/deleteArticleImg'
            }),
            // 提交表单
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFields(async (err, values) => {
                    if (!err) {
                        /* if (!this.editStatus && values.mdFile.length >= 2) {
                            return this.$message.error('只能上传一个文件请删除后重试')
                        } */
                        // if (!this.editStatus && values.mdFile.length === 0) {
                        //     return this.$message.error('请上传文档')
                        // }
                        if (!this.editStatus && values.article_cover.length === 0) {
                            return this.$message.error('请上传文章封面图')
                        }
                        let form = {
                            ...values,
                        }
                        let data
                        try {
                            if (!this.editStatus) {
                                form['article_cover'] = values.article_cover[values.article_cover.length - 1].response.data.url,
                                    // form['mdUrl'] = values.mdFile[0].response.data
                                    form['mark_content'] = this.mark_content
                                data = await this.vxHandlerSaveArticle(form)

                            } else { // 编辑模式
                                form['id'] = this.$route.query.articleId
                                data = await this.vxChangeArticleInfo(form)
                                /*  this.$router.push({name: 'article-manage'}) */
                            }
                            if (data.code) {
                                this.$message.success(data.msg)
                                this.$refs.publishForm.form.resetFields()

                            } else {
                                this.$message.error(data.msg)
                            }
                        } catch (error) {
                            console.log(error);
                            this.$message.error('请稍后重试')

                        }
                        console.log('Received values of form: ', form);
                        console.log(data);
                    }
                });
            },
            normFile(e) {
                console.log('Upload event:', e);
                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;
            },
            handleChange(info) {
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    return;
                }
                if (info.file.status === 'done') {
                    // Get this url from response in real world.
                    getBase64(info.file.originFileObj, imageUrl => {
                        this.imageUrl = imageUrl;
                        this.loading = false;
                    });
                }
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJpgOrPng) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                    this.$message.error('Image must smaller than 10MB!');
                }
                return isJpgOrPng && isLt10M;
            },

            async handlerDelete(e) {
                let mdUrl = e.response.data.path
                const data = await this.vxHandlerDelete({mdUrl})
                console.log(data);
            },
            async getOneArticle(id) {
                const {data} = await this.vxGetOneArticle({articleId: id})
                console.log(data);
                this.$refs.publishForm.form.setFieldsValue({
                    articleTitle: data.articleTitle,
                    articleDesc: data.articleDesc,
                    articleClassify: data.articleClassify,
                    articleType: data.articleType,
                    articleTag: data.articleTag,

                })
            },
            saveArticle(value) { // 没必要，啥也不用做，主要是发布的时候，带过去
                console.log(value);
                // this.vxChangeArticleMD({id: this.$route.query.articleId, value})
            },


            $imgAdd(pos, $file) {
                // 第一步.将图片上传到服务器.
                var formdata = new FormData();
                formdata.append('image', $file);
                this.$axios({
                    url: '/root/articleImg',
                    method: 'post',
                    data: formdata,
                    headers: {'Content-Type': 'multipart/form-data'},
                }).then((url) => {
                    // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                    /**
                    * $vm 指为mavonEditor实例，可以通过如下两种方式获取
                    * 1. 通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
                    * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
                    */
                    console.log(url.data.url);
                    let imgUrl = url.data.url
                    this.$refs.md.$img2Url(pos, imgUrl);
                })
            },
            $imgDel(pos, $file) {
                // api/articleImg/1647247760035image.png 后台返回的链接是这样的，需要把api去掉
                // delete this.img_file[pos];/
                this.vxDeleteArticleImg({imgUrl: pos[0].substring(3)})
            }
        },
        mounted() {
            if (this.$route.query.articleId) {
                this.getOneArticle(this.$route.query.articleId)
            }
        }
    };
</script>
<style lang="less" >
    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
    }
    .PublishArticle {
        background-color: #fff;
        #components-form-demo-validate-other {
            // max-width: 1500px;
        }
        .avatar-uploader > .ant-upload {
            width: 128px;
            height: 128px;
        }
        .ant-upload-select-picture-card i {
            font-size: 32px;
            color: #999;
        }

        .ant-upload-select-picture-card .ant-upload-text {
            margin-top: 8px;
            color: #666;
        }
        .PublishArticle-from {
            display: flex;
            flex-wrap: wrap;
            .ant-form-item {
                min-width: 50%;
            }
            .mavon-editor {
                flex: 1;
                width: 1400px;
                min-width: @min-width;
            }
            .mt-10px {
                margin-top: 10px;
            }
            .article-cover {
                max-height: 200px;
            }
        }
    }
</style>